var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import MuiChip from '@mui/material/Chip';
import { forwardRef, useEffect, useMemo, useState, } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-light/xmark-light.svg';
import Icon from '@shared/ui/icons/Icon';
import getSelectTagStyles from './styles';
import { defineComponentValue, defineInitValues, updateOptionsState } from './utils';
const TagComponent = styled(MuiChip, {
    shouldForwardProp: propName => propName !== 'itemVariant' &&
        propName !== 'multiple' && propName !== 'fullWidth',
})(props => ({
    '&': {
        width: props.fullWidth ? '100%' : 'unset',
        padding: getSelectTagStyles(props.theme).padding,
        borderRadius: getSelectTagStyles(props.theme).borderRadius,
        height: 'auto',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: props.selected ?
            getSelectTagStyles(props.theme).backgroundColorSelected :
            getSelectTagStyles(props.theme).backgroundColor,
        fontSize: 16,
        userSelect: getSelectTagStyles(props.theme).userSelect,
        border: props.selected ?
            getSelectTagStyles(props.theme).borderSelected :
            getSelectTagStyles(props.theme).border,
        cursor: props.selected && !props.multiple ? 'default' : 'pointer',
        boxShadow: props.selected ?
            getSelectTagStyles(props.theme).boxShadowActive :
            getSelectTagStyles(props.theme).boxShadow,
        transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 250ms',
        outline: getSelectTagStyles(props.theme).outline,
        ':hover': {
            borderColor: getSelectTagStyles(props.theme).borderColorHover,
            backgroundColor: props.selected ?
                getSelectTagStyles(props.theme).backgroundColorSelected :
                getSelectTagStyles(props.theme).backgroundColorHover,
            transitionDuration: '250ms',
        },
        ':active': {
            outline: getSelectTagStyles(props.theme).outlineActive,
            boxShadow: props.selected ?
                getSelectTagStyles(props.theme).boxShadowSelectedActive :
                getSelectTagStyles(props.theme).boxShadowActive,
        },
    },
    '&.Mui-disabled': {
        border: getSelectTagStyles(props.theme).borderDisabled,
    },
    '& .MuiChip-icon': {
        margin: 0,
    },
    '& .MuiChip-deleteIcon': {
        marginLeft: props.theme.spacing_sizes.s,
        cursor: props.selected ? 'pointer' : 'default',
        color: props.selected ?
            getSelectTagStyles(props.theme).colorSelected :
            getSelectTagStyles(props.theme).color,
        fontSize: 16,
        ':hover': {
            color: props.selected ?
                getSelectTagStyles(props.theme).colorSelected :
                getSelectTagStyles(props.theme).color,
        },
    },
    '& .MuiChip-label': {
        width: '100%',
        padding: '0',
        color: props.selected ?
            getSelectTagStyles(props.theme).colorSelected :
            getSelectTagStyles(props.theme).color,
    },
}));
const Tag = (_a) => {
    var { onClick = undefined, onDelete = undefined, endElement = undefined, startElement = undefined, value, showDeleteBtn = false, multiple = false } = _a, props = __rest(_a, ["onClick", "onDelete", "endElement", "startElement", "value", "showDeleteBtn", "multiple"]);
    const handleItemClick = () => {
        if (multiple) {
            if (onClick && !props.disabled) {
                onClick(value);
            }
        }
        else if (onClick && !props.selected && !props.disabled) {
            onClick(value);
        }
    };
    const handleDelete = () => {
        if (onDelete && props.selected && !props.disabled) {
            onDelete(value);
        }
    };
    const DeleteIcon = useMemo(() => {
        if (endElement) {
            return endElement;
        }
        if (showDeleteBtn && props.selected) {
            return _jsx(Icon, { IconComponent: XMarkIcon });
        }
        return undefined;
    }, [endElement, showDeleteBtn, props.selected]);
    return (_jsx(TagComponent, Object.assign({}, props, { icon: startElement, deleteIcon: DeleteIcon, multiple: multiple, 
        // onClick doesn't fires which clicking on deleteIcon element
        onDelete: showDeleteBtn && props.selected ? handleDelete : undefined, onClick: handleItemClick })));
};
const SelectTagContainer = styled('div', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'row',
    gap: props.theme.spacing_sizes.m,
    width: props.fullWidth ? '100%' : 'auto',
}));
const SelectTagComponent = ({ options: initOptions = [], onChange = undefined, onDelete = undefined, fullWidth = undefined, showDeleteBtn = undefined, value: componentValue = '', multiple = false, }, ref) => {
    const [options, updateOptions] = useState(() => defineInitValues(initOptions, componentValue));
    const handleItemClick = (value) => {
        const { selectedOptionsValues, newOptions, } = updateOptionsState(options, value, multiple);
        updateOptions(newOptions);
        if (onChange && multiple === false) {
            onChange(value);
        }
        else if (onChange) {
            onChange(selectedOptionsValues);
        }
    };
    const handleItemDelete = (value) => {
        updateOptions((prevOptions) => {
            const newState = Object.assign({}, prevOptions);
            if (newState[value]) {
                newState[value] = Object.assign(Object.assign({}, newState[value]), { selected: false });
            }
            return Object.assign({}, newState);
        });
        if (onDelete) {
            onDelete(value);
        }
    };
    useEffect(() => {
        updateOptions((prevOptions) => defineComponentValue(prevOptions, componentValue));
    }, [componentValue]);
    return (_jsx(SelectTagContainer, { ref: ref, fullWidth: fullWidth, children: Object.keys(options).map((optionKey) => (_createElement(Tag, Object.assign({}, options[optionKey], { key: options[optionKey].value, onClick: handleItemClick, onDelete: handleItemDelete, showDeleteBtn: showDeleteBtn, fullWidth: fullWidth, multiple: multiple })))) }));
};
const SelectTag = forwardRef(SelectTagComponent);
export default SelectTag;
