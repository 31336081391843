const getSelectTagStyles = (theme) => ({
    padding: `${theme.spacing_sizes.s}px ${theme.spacing_sizes.m}px`,
    border: 'none',
    color: theme.palette.text.primary,
    colorSelected: theme.palette.text.disabled,
    borderSelected: 'none',
    borderRadius: 20,
    backgroundColor: '#F8F9FA',
    backgroundColorSelected: theme.palette.primary.main,
    backgroundColorHover: theme.customColors.button.pill.border,
    backgroundColorActiveHover: theme.palette.primary.dark,
    outline: 'transparent',
    outlineActive: `1px solid ${theme.palette.primary.main}`,
    boxShadowActive: '0px 0px 0px 3px rgba(186, 189, 199, 0.20)',
    boxShadowSelectedActive: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
    endElementPadding: '',
});
export default getSelectTagStyles;
