export const getSelectStyles = (theme) => ({
    variants: {
        outlined: {
            backgroundColor: theme.customColors.surface.surface,
            disabledBackgroundColor: theme.customColors.input.backgroundDisabled,
            selectInnerColor: theme.palette.text.primary,
            border: `1px solid ${theme.customColors.input.border}`,
            borderError: `1px solid ${theme.palette.error.main}`,
            size: {
                small: {
                    padding: `${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 4}px
                              ${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 1.5}px`,
                    iconSize: 20,
                    endAdornmentRight: '12px',
                    endAdornmentTop: 'calc(50% - 10px)',
                    labelMarginBottom: theme.spacing_sizes.xs * 1.25,
                    labelFontSize: 14,
                    labelLineHeight: '20px',
                },
                medium: {
                    padding: `${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 4}px
                              ${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 1.5}px`,
                    iconSize: 20,
                    endAdornmentRight: '12px',
                    endAdornmentTop: 'calc(50% - 10px)',
                    labelMarginBottom: theme.spacing_sizes.xs * 1.25,
                },
                large: {
                    padding: `${theme.spacing_sizes.xs * 2}px ${theme.spacing_sizes.xs * 5}px
                              ${theme.spacing_sizes.m}px ${theme.spacing_sizes.xs * 2}px`,
                    iconSize: 24,
                    endAdornmentRight: '16px',
                    endAdornmentTop: 'calc(50% - 10px)',
                    labelMarginBottom: theme.spacing_sizes.m,
                    labelFontSize: 16,
                    labelLineHeight: '24px',
                },
            }
        },
        'date-period': {
            backgroundColor: theme.customColors.menu.item.select,
            selectInnerColor: theme.palette.text.secondary,
            border: 'unset',
            borderHover: '1px solid #EFF1F3',
            borderActive: '1px solid #EFF1F3',
            size: {
                small: {
                    padding: `${theme.spacing_sizes.s}px ${theme.spacing_sizes.xs * 3.25}px
                              ${theme.spacing_sizes.s}px ${theme.spacing_sizes.xs * 1.25}px`,
                    iconSize: 16,
                    endAdornmentRight: '10px',
                    endAdornmentTop: 'calc(50% - 8px)',
                },
                medium: {
                    padding: `${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 4}px
                              ${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 1.5}px`,
                    iconSize: 20,
                    endAdornmentRight: '12px',
                    endAdornmentTop: 'calc(50% - 10px)',
                },
                large: {
                    padding: `${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 4}px
                              ${theme.spacing_sizes.xs * 1.25}px ${theme.spacing_sizes.xs * 1.5}px`,
                    iconSize: 20,
                    endAdornmentRight: '12px',
                    endAdornmentTop: 'calc(50% - 10px)',
                },
            }
        },
    },
});
export const getSelectMenuStyles = (theme) => ({
    variants: {
        outlined: {
            menuMarginTop: `${theme.spacing_sizes.s}px`,
            menuBorder: `1px solid ${theme.customColors.input.border}`,
            menuBoxShadow: `0px 10px 15px -3px ${theme.customColors.surface.shadow}`,
            itemBorderLeft: '3px solid transparent',
            itemHoverBackground: theme.customColors.menu.item.hover,
            selectedItemHoverBackground: theme.customColors.menu.item.hover,
            itemActiveBackground: theme.customColors.menu.item.active,
            selectedItemBackground: theme.customColors.menu.item.select,
            selectedItemBackgroundHighlited: theme.customColors.button.secondary.backgroundHover,
            selectedItemBorderLeft: `3px solid ${theme.customColors.input.borderActive}`,
            padding: '10px 12px',
        },
        'date-period': {
            menuMarginTop: `${theme.spacing_sizes.s}px`,
            menuBorder: `1px solid ${theme.customColors.menu.period_border}`,
            menuBoxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.08)',
            itemHoverBackground: theme.customColors.menu.item.select,
            selectedItemHoverBackground: theme.customColors.menu.item.select,
            selectedItemBackground: theme.customColors.menu.item.select,
            selectedItemBackgroundHighlited: theme.customColors.menu.item.select,
            itemActiveBackground: theme.customColors.menu.item.select,
            padding: '10px 12px',
        },
    },
});
